import useCurrencies from "../../../hooks/useCurrencies";

export default function Currency({onChange, value}) {
	const {currencies} = useCurrencies();

	return (
		<>
			<label htmlFor="currencyId" className={"form-label"}>Billing currency:</label>
			<select name="currencyId" id={"currencyId"} className={"form-select"} defaultValue={1} value={value} required={true} onChange={onChange}>
                <option value={""}>Select an option</option>
				{currencies.map(value => {
					return <option key={value.id} value={value.id}>{value.name}</option>
				})}
			</select>
		</>
	);
}
