import {useContext, useState, useRef} from "react";
import Spinner from "../../Spinner";
import purchaseOrderContext from "../../../contexts/purchaseOrders/purchaseOrderContext";
import customerContext from "../../../contexts/customers/customerContext";
import Error from "../../alerts/Error";
import "../../../css/inputNumber.css";
import billingEntityContext from "../../../contexts/billingEntities/billingEntityContext";
import Currency from "./Currency";

const initialPurchaseOrder = {
	number: '',
	description: '',
	totalValue: '',
	currencyId: '',
	pdfContent: '',
	pdfFileName: '',
	customerId: '',
	comments: '',
	issueDate: '',
    billingEntityId: '',
    clientContact: ''
};

function AddPurchaseOrder() {
	const [purchaseOrder, setPurchaseOrder] = useState(initialPurchaseOrder);
	const [error, setError] = useState(null);
	const {customers} = useContext(customerContext);
    const {billingEntities} = useContext(billingEntityContext);
	const fileRef = useRef(null);

	const {createPurchaseOrder, loading} = useContext(purchaseOrderContext);

	async function createPO(purchaseOrder, pdfContent=null, pdfFileName=null) {
		try {
			if (pdfContent) {
				await createPurchaseOrder({
					...purchaseOrder,
					pdfContent: pdfContent,
					pdfFileName: pdfFileName
				});
			}
			else {
				await createPurchaseOrder(purchaseOrder);
			}
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		if (fileRef.current.files.length > 0) {
			const pdfFileName = fileRef.current.files[0].name;
			const reader = new FileReader();
			reader.onloadend = async (e) => {
				await createPO(purchaseOrder, e.target.result, pdfFileName);
				setPurchaseOrder(initialPurchaseOrder);
			}
			reader.readAsDataURL(fileRef.current.files[0]);
		}
		else {
			await createPO(purchaseOrder);
		}

	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setPurchaseOrder({
					...purchaseOrder,
					[evt.target.id]: evt.target.checked
				});
				break;
			case "number":
				const number = parseFloat(evt.target.value);
				setPurchaseOrder({
					...purchaseOrder,
					[evt.target.id]: number
				});
				break
			default:
				setPurchaseOrder({
					...purchaseOrder,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addPurchaseOrderModal"}>Add PO</button>
			<div className={"modal fade"} id={"addPurchaseOrderModal"} tabIndex="-1" aria-labelledby="addPurchaseOrderModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addPurchaseOrderModalLabel"}>Add PO</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="number" className={"form-label"}>Number:</label>
										<input type="text" id={"number"} value={purchaseOrder.number} className={"form-control"} placeholder={"1234"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="description" className={"form-label"}>Description:</label>
										<textarea id={"description"} value={purchaseOrder.description} className={"form-control"} placeholder={"PO description"} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="totalValue" className={"form-label"}>Total value:</label>
										<input type="number" id={"totalValue"} className={"form-control"} value={purchaseOrder.totalValue} placeholder={"1000"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="pdfContent" className={"form-label"}>Reference PDF:</label>
										<input ref={fileRef} type="file" id={"pdfContent"} className={"form-control"} required={false}/>
									</div>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<Currency onChange={onChange} value={purchaseOrder.currencyId}/>
									</div>
									<div className={"col"}>
										<label htmlFor="customerId" className={"form-label"}>Customer:</label>
										<select name="customerId" id={"customerId"} className={"form-select"} value={purchaseOrder.customerId} required={true} onChange={onChange}>
											<option value="">Select an option</option>
											{customers.map(customer => {
												return <option key={customer.id} value={customer.id}>{customer.fullName}</option>;
											})}
										</select>
									</div>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"issueDate"} className={"form-label"}>Issue Date:</label>
										<input id={"issueDate"} type={"date"} name={"PO date"} className={"form-control"} value={purchaseOrder.issueDate} required={true} onChange={onChange} />
									</div>
									<div className={"col"}>
										<label htmlFor="comments" className={"form-label"}>Comments:</label>
										<textarea id={"comments"} value={purchaseOrder.comments} className={"form-control"} placeholder={"PO comments"} required={false} onChange={onChange}/>
									</div>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="billingEntityId" className={"form-label"}>Billing Entity:</label>
										<select name="billingEntityId" id={"billingEntityId"} className={"form-select"} value={purchaseOrder.billingEntityId} required={true} onChange={onChange}>
											<option value="">Select an option</option>
											{billingEntities.map(billingEntity => {
												return <option key={billingEntity.id} value={billingEntity.id}>{billingEntity.name}</option>;
											})}
										</select>
									</div>
									<div className={"col"}>
										<label htmlFor="clientContact" className={"form-label"}>Client contact:</label>
										<textarea id={"clientContact"} value={purchaseOrder.clientContact} className={"form-control"} placeholder={"PO client contact"} required={false} onChange={onChange}/>
									</div>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"} disabled={loading}>{loading ? <Spinner />: 'Add purchase order'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddPurchaseOrder;
