import {useContext, useState, useRef} from "react";
import purchaseOrderContext from "../../../contexts/purchaseOrders/purchaseOrderContext";
import customerContext from "../../../contexts/customers/customerContext";
import Error from "../../alerts/Error";
import "../../../css/inputNumber.css";
import billingEntityContext from "../../../contexts/billingEntities/billingEntityContext";


function ExportPurchaseOrders() {
    const {purchaseOrders} = useContext(purchaseOrderContext);

    async function exportPurchaseOrders() {
        if (purchaseOrders.length < 1) {
            return;
        }

        const headers = ["Number", "Customer", "BillingEntity", "Description", "Total value", "Currency", "Value invoiced", "Value in progress", "Value remaining", "Value remaining incl in progress", "Issue date", "Comments"];
        const rows = [headers.join("\t"), ...purchaseOrders.map(row => {
            console.log(row);
            return [
                row.number,
                row.customer.fullName,
                row.billingEntity?.name || "",
                row.description,
                row.totalValue,
                row.currency.name,
                row.valueConsumed,
                row.valueInProgress,
                row.valueRemaining,
                row.valueRemainingInclInProgress,
                row.issueDate,
                row.comments
            ].join("\t");
        })];

        const tsvContent = rows.join("\n");
        const blob = new Blob([tsvContent], { type: "text/tab-separated-values" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "POs_export.tsv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

	return (
        <button className={"btn btn-secondary"} onClick={exportPurchaseOrders}>Export POs</button>
	);
}

export default ExportPurchaseOrders;
