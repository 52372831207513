import {useContext, useEffect, useState} from "react";
import purchaseOrderContext from "../../../contexts/purchaseOrders/purchaseOrderContext";
import Spinner from "../../Spinner";
import PurchaseOrderRow from "./PurchaseOrderRow";
import Pagination from "../../Pagination";
import purchaseOrderTypes from "../../../contexts/purchaseOrders/purchaseOrderTypes";

function PurchaseOrders() {
	const {purchaseOrders, pageInfo, loading, dispatch} = useContext(purchaseOrderContext);

	function setLimit(newLimit) {
		dispatch({
			type: purchaseOrderTypes.SET_PAGE_INFO,
			payload: {
				...pageInfo,
				limit: parseInt(newLimit)
			}
		});
	}

	function setOffset(newOffset) {
		dispatch({
			type: purchaseOrderTypes.SET_PAGE_INFO,
			payload: {
				...pageInfo,
				offset: parseInt(newOffset)
			}
		});
	}

	return (
		<div className={"table-responsive overflow-auto"}>
			<Pagination limit={pageInfo.limit} offset={pageInfo.offset} hasNextPage={pageInfo.hasNextPage} hasPreviousPage={pageInfo.hasPreviousPage} setLimit={setLimit} setOffset={setOffset} maxOffset={pageInfo.maxOffset}/>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Number</th>
					<th>Description</th>
					<th>Total value</th>
					<th>Billing currency</th>
					<th>Reference PDF</th>
					<th>Value invoiced</th>
					<th>Value in progress</th>
					<th data-bs-toggle="tooltip" data-bs-replacement="top" title="Total minus invoiced">Value remaining</th>
					<th data-bs-toggle="tooltip" data-bs-replacement="top" title="Total minus invoiced and in progress">Value remaining incl in progress</th>
					<th>Customer</th>
					<th>Issue date</th>
					<th>Comments</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{loading && <tr><td colSpan={6}><Spinner /></td></tr>}
					{purchaseOrders.map(value => {
						return <PurchaseOrderRow purchaseOrder={value} key={value.id} />;
					})
				}
				</tbody>
			</table>
		</div>
	)
}

export default PurchaseOrders;
