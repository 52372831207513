import context from "./billingEntityContext";
import React, {useContext, useEffect, useReducer, useRef} from "react";
import reducer from "./billingEntityReducer";
import types from "./billingEntityTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";


const initialState = {
	loading: false,
	billingEntities: [],
};


export default ({children}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);
	const abortController = useRef(null);

	useEffect(() => {
		abortController.current = new AbortController();

		(async () => {
			try {
				await getBillingEntities();
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.current.abort();
		}
	}, []);

	async function getBillingEntities() {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			signal: abortController.current.signal,
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getBillingEntities {
					  getBillingEntities {
						billingEntities {
                          id
                          name
                        }
					  }
					}
				`
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_BILLING_ENTITIES,
					payload: json.data.getBillingEntities.billingEntities
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getBillingEntities: getBillingEntities,
			dispatch
		}}>
			{children}
		</context.Provider>
	);
}
