import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {NotificationContext} from "../contexts/NotificationContext";

export default function useCurrencies () {
	const [loading, setLoading] = useState(true);
	const {token, setToken} = useContext(AuthContext);
	const [currencies, setCurrencies] = useState([]);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		let isMounted = true;
		const abortController = new AbortController();

		(async () => {
			try {
				const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
					method: "POST",
					signal: abortController.signal,
					headers: {
						"Content-Type": "application/json",
						'Authorization': 'Bearer ' + token
					},
					body: JSON.stringify({
						query: `
					query getCurrencies {
					  getCurrencies {
						id
						name
					  }
					}
						`
					})
				});
				if (response.status === 401) {
					isMounted && setToken(null);
				}
				else {
					const json = await response.json();

					if (json.errors) {
						for (let i = 0; i < json.errors.length; i++) {
							const {data} = json.errors[i];

							if (data && data.length > 0) {
								for (let j = 0; j < data.length; j++) {
									addToast({
										message: json.errors[i].message,
										description: data[j]
									});
								}
							} else {
								addToast({
									message: json.errors[i].message,
									description: ""
								});
							}
						}
					} else {
						console.log(json.data.getCurrencies);
						isMounted && setCurrencies(json.data.getCurrencies);
					}
				}
			}
			catch (e) {
				console.error(e);
			}
			finally {
				isMounted && setLoading(false);
			}
		})();

		return () => {
			isMounted = false;
			abortController.abort();
		}
	}, []);

	return {
		loading, currencies
	};
}