import SortState from "../../../contexts/sort/SortState";
import PurchaseOrderState from "../../../contexts/purchaseOrders/purchaseOrderState";
import CustomerState from "../../../contexts/customers/CustomerState";
import PageDiv from "../../PageDiv";
import AddPurchaseOrder from "./AddPurchaseOrder";
import ExportPurchaseOrders from "./ExportPurchaseOrders";
import PurchaseOrders from "./PurchaseOrders";
import PurchaseOrderFilters from "./PurchaseOrderFilters";
import BillingEntityState from "../../../contexts/billingEntities/billingEntityState";

function PurchaseOrdersPage() {
	return (
		<PageDiv>
			<SortState initialColumn={'purchaseOrders.id'}>
				<PurchaseOrderState>
                    <BillingEntityState>
                        <CustomerState>
                            <div className="d-flex gap-2">
                                <AddPurchaseOrder />
                                <ExportPurchaseOrders />
                            </div>
                            <hr />
                            <PurchaseOrderFilters />

                            <hr />
                            <PurchaseOrders />
                        </CustomerState>
                    </BillingEntityState>
				</PurchaseOrderState>
			</SortState>
		</PageDiv>
	)
}

export default PurchaseOrdersPage;
