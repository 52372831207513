import EditPurchaseOrder from "./EditPurchaseOrder";
import RemovePurchaseOrder from "./RemovePurchaseOrder";
import { saveAs } from "file-saver";
import { useContext } from "react";
import {AuthContext} from "../../../contexts/AuthContext";

function PurchaseOrderRow({purchaseOrder}) {
    const {token} = useContext(AuthContext);

    async function getPDF(evt) {
        evt.preventDefault()
        try {
            const response = await fetch(`${process.env.REACT_APP_endPoint}/purchaseOrders/downloadPDF/${purchaseOrder.id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                const blob = await response.blob();
                saveAs(blob, purchaseOrder.pdfFileName);
            }
            else {
                console.log(response);
            }
        }
        catch (e) {
            console.error(e);
        }
    }
	return (
		<tr>
			<td>{purchaseOrder.number}</td>
			<td>{purchaseOrder.description}</td>
			<td>{purchaseOrder.totalValue.toFixed(2)}</td>
			<td>{purchaseOrder.currency.name}</td>
			<td><a href="#" onClick={getPDF}>{purchaseOrder.pdfFileName}</a></td>
			<td>{purchaseOrder.valueConsumed.toFixed(2)}</td>
			<td>{purchaseOrder.valueInProgress.toFixed(2)}</td>
			<td>{purchaseOrder.valueRemaining.toFixed(2)}</td>
			<td>{purchaseOrder.valueRemainingInclInProgress.toFixed(2)}</td>
			<td>{purchaseOrder.customer.fullName}</td>
			<td>{purchaseOrder.issueDate}</td>
			<td>{purchaseOrder.comments}</td>

			<td>
				<div className={"d-flex gap-2"}>
					<EditPurchaseOrder purchaseOrder={purchaseOrder}/>
					<RemovePurchaseOrder purchaseOrder={purchaseOrder}/>
				</div>
			</td>
		</tr>
	);
}

export default PurchaseOrderRow;
